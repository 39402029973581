import React, { useCallback, useEffect, useState } from "react";
import { Button, List, message, Popconfirm, Result, Row, Space, Tooltip, Typography } from "antd";
import { Link, Route, Routes, useNavigate } from "react-router-dom";
import MessageApiContext from "../../../../context/message-api";
import { DeleteOutlined, UnorderedListOutlined } from "@ant-design/icons";
import CreateTask from "./Create";
import { deleteTask, getTasks } from "../../../../services/tasks";
import { tasksInformation } from "../../../../constants";

export default function Tasks({ assistantId }) {
    return (
        <Routes>
            <Route path={'/'} element={<TaskList assistantId={assistantId} />} />
            <Route path={'/create'} element={<CreateTask assistantId={assistantId} />} />
        </Routes>
    )
}

function TaskList({ assistantId }) {
    const [loading, setLoading] = useState(false);
    const [tasks, setTasks] = useState([]);
    const messageApi = React.useContext(MessageApiContext);
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(0);
    const navigate = useNavigate();

    const getTaskList = useCallback(async () => {
        setLoading(true);
        getTasks(assistantId)
            .then(({ data, total }) => {
                setTasks(data);
                setTotal(total);
            })
            .catch((err) => {
                messageApi.error("Não foi possível carregar tarefas")
                console.log(err)
            })
            .finally(() => setLoading(false));
    }, [assistantId, messageApi])

    useEffect(() => {
        getTaskList();
    }, [getTaskList]);

    const update = () => {
        getTaskList();
    }

    const CreateButton = ({ size }) =>
        <Button onClick={() => navigate(`create`)}
            icon={<UnorderedListOutlined />}
            size={size}
            type="primary"
            key="criar">
            Criar
        </Button>;

    return (
        <>
            <List
                loading={loading}
                dataSource={tasks}
                pagination={{
                    position: "bottom",
                    align: "end",
                    onChange: (page) => {
                        setPage(page);
                    },
                    total,
                    pageSize: 5,
                    current: page,
                }}
                header={
                    <Row justify={'space-between'}>
                        <Typography.Title level={3}>Tarefas</Typography.Title>
                        <Space direction={'horizontal'}>
                            <CreateButton size={'middle'} />
                        </Space>
                    </Row>
                }
                locale={{
                    emptyText: <Result
                        title={`Tarefas são eventos que ocorrem em horários previamente agendados.`}
                        subTitle={`Use tarefas para, por exemplo colocar mensagens ativas no seu assistente.`}
                        extra={[
                            <CreateButton size={'large'} />,
                        ]}
                    />
                }}
                renderItem={(item, key) => {
                    return (<TaskItem onSuccess={update} key={key} item={item} />)
                }}
            />
        </>
    )
}


const TaskItem = ({ onSuccess, item }) => {
    const [deleting, setDeleting] = useState(false);
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");

    const getTaskInfo = useCallback(() => {
        const task = tasksInformation.find((task) => task.type === item.type);
        return task;
    }, [item]);

    useEffect(() => {
        const { title, description } = getTaskInfo();
        setTitle(title);       
        setDescription(description); 
    }, [getTaskInfo]);

    const onDelete = () => {
        setDeleting(true);
        deleteTask(item.assistantID, item.id)
            .then(() => {
                message.success("Tarefa deletada");
                setDeleting(false);
                onSuccess();
            })
            .catch((err) => {
                message.error("Não foi possível deletar a tarefa");
                console.log(err);
            })
    }

    return (
        <List.Item actions={[
            <Tooltip title={'Em breve'}>
                {/* <Link to={`${item?.id}`}> */}
                <Button variant={'outlined'}>Editar</Button>
                {/* </Link> */}
            </Tooltip>,
            <Popconfirm cancelText={'Cancelar'}
                onConfirm={onDelete}
                title={"Tem certeza?"}
                description={"Essa ação vai deletar a tarefa"}>
                <Button disabled={deleting} loading={deleting} icon={<DeleteOutlined />} danger />
            </Popconfirm>
        ]}>
            <List.Item.Meta
                title={title} 
                description={description}/>
        </List.Item>
    )
}