import { Button, Steps, theme } from "antd";
import { useState } from "react";
import { Message, DateSchedule, SelectInstance, Contacts } from "./Steps";

export default function ScheduledForm({ form, instances }) {
    const { token } = theme.useToken();
    const [current, setCurrent] = useState(0);

    const next = () => {
        const currentStepFields = steps[current].fields;

        form.validateFields(currentStepFields)
            .then(() => {
                setCurrent(current + 1);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const prev = () => {
        setCurrent(current - 1);
    };

    const steps = [
        {
            title: 'Integração',
            content: <SelectInstance instanceOptions={instances} />,
            stepKey: 'instance',
            fields: ['instance']
        },
        {
            title: 'Horário',
            content: <DateSchedule />,
            stepKey: 'dateSchedule',
            fields: ['dateSchedule']
        },
        {
            title: 'Tipo',
            content: <Message form={form} />,
            stepKey: 'messageType',
            fields: ['messageType']
        },
        {
            title: 'Contatos',
            content: <Contacts />,
            stepKey: 'contacts',
            fields: ['contacts']
        },
    ];

    const items = steps.map((item) => ({
        key: item.title,
        title: item.title,
    }));

    const contentStyle = {
        lineHeight: '260px',
        textAlign: 'center',
        color: token.colorTextTertiary,
        borderRadius: token.borderRadiusLG,
        border: `1px dashed ${token.colorBorder}`,
        marginTop: 16,
        padding: '16px 12px'
    };

    return (
        <>
            <Steps current={current} items={items} />
            <div style={contentStyle}>
                {steps[current].content}
            </div>
            <div
                style={{
                    marginTop: 24,
                }}
            >
                {current < steps.length - 1 && (
                    <Button type="primary" onClick={() => next()}>
                        Próximo
                    </Button>
                )}
                {current === steps.length - 1 && (
                    <Button type="primary" onClick={form.submit}>
                        Criar
                    </Button>
                )}
                {current > 0 && (
                    <Button
                        style={{
                            margin: '0 8px',
                        }}
                        onClick={() => prev()}
                    >
                        Anterior
                    </Button>
                )}
            </div>
        </>
    );
}
