import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons"
import { Button, Form, Input, Mentions, Select, TimePicker } from "antd"

const actions = [
    {
        label: "Múltiplas mensagens",
        value: "MULTIPLE_MESSAGES",
    }
]

export const SelectInstance = ({ instanceOptions }) => {
    return (
        <Form.Item label={"Instância do Whatsapp"} name={'integrationID'}
            rules={[
                {
                    required: true,
                    message: 'Deve escolher uma instância',
                },
            ]}
        >
            <Select placeholder={"Selecione a instância"} options={instanceOptions} />
        </Form.Item>
    )
}

const mentionsOptions = [
    {
        value: "name",
        label: "name"
    }
]

export const Message = ({ form }) => {
    const messageType = Form.useWatch('type', form);

    return (
        <>
            <Form.Item label={'Tipo de ação'} name={'type'}>
                <Select options={actions} placeholder={"Selecione o tipo de ação"} />
            </Form.Item>
            {messageType === "MULTIPLE_MESSAGES" && (
                <Form.List name="messages">
                    {(fields, { add, remove }) => (
                        <div style={{ maxHeight: '200px', overflowY: 'auto' }}>
                            {fields.map(({ key, name, ...restField }) => (
                                <div
                                    key={key}
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        marginBottom: 8
                                    }}
                                >
                                    <Form.Item
                                        {...restField}
                                        name={[name, 'text']}
                                        rules={[{ required: true, message: 'Deve conter uma mensagem' }]}
                                        style={{ flex: 1, marginBottom: 0 }}
                                    >
                                        <Mentions
                                            options={mentionsOptions}
                                            prefix={'$'}
                                            placeholder="Mensagem"
                                            style={{ height: '32px' }}
                                        />
                                    </Form.Item>
                                    <MinusCircleOutlined
                                        onClick={() => remove(name)}
                                        style={{ fontSize: '20px', marginLeft: 8 }}
                                    />
                                    <Form.Item name={[name, 'image']} initialValue={''} noStyle />
                                </div>
                            ))}
                            <Form.Item>
                                <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                    Adicionar mensagem
                                </Button>
                            </Form.Item>
                        </div>
                    )}
                </Form.List>
            )}
        </>
    );
};

export const DateSchedule = () => {
    const format = 'HH:mm';

    return (
        <Form.Item label={'Selecione um horário'} name={'dateSchedule'}
            rules={[
                {
                    required: true,
                    message: 'Deve escolher um horário',
                },
            ]}
        >
            <TimePicker size={'large'} placeholder="HH:mm" format={format} />
        </Form.Item>
    )
}

export const Contacts = () => {
    return (
        <Form.List name="contacts">
            {(fields, { add, remove }) => (
                <>
                    <div style={{ maxHeight: '200px', overflowY: 'auto' }}>
                        {fields.map(({ key, name, ...restField }) => (
                            <div
                                key={key}
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    marginBottom: 8,
                                    gap: 12
                                }}
                            >
                                <Form.Item
                                    {...restField}
                                    name={[name, 'name']}
                                    rules={[{ required: true, message: 'Deve conter um nome do contato' }]}
                                    style={{ flex: 1, marginBottom: 0 }}
                                >
                                    <Input
                                        placeholder="Nome"
                                        style={{ height: '32px' }}
                                    />
                                </Form.Item>
                                <Form.Item
                                    {...restField}
                                    name={[name, 'to']}
                                    rules={[{ required: true, message: 'Deve conter um número válido' }]}
                                    style={{ flex: 1, marginBottom: 0 }}
                                >
                                    <Input
                                        placeholder="Número"
                                        style={{ height: '32px' }}
                                    />
                                </Form.Item>
                                <Form.Item name={[name, 'tag']} initialValue={''} noStyle />
                                <MinusCircleOutlined
                                    onClick={() => remove(name)}
                                    style={{ fontSize: '20px', marginLeft: 8 }}
                                />
                            </div>
                        ))}
                        <Form.Item>
                            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                Adicionar contato
                            </Button>
                        </Form.Item>
                    </div>
                </>
            )}
        </Form.List>
    )
}