import React, { useCallback, useContext, useEffect, useState } from "react";
import { Link, Route, Routes } from "react-router-dom";
import { Button, Empty, List, Typography } from "antd";
import { getGenerativeSessionHistory, getGenerativeSessions } from "../../../../services/generative-message-logs";
import { EyeOutlined } from "@ant-design/icons";
import { formatPhoneNumber, updatedSince } from "../../../../utils";
import MessageApiContext from "../../../../context/message-api";
import View from "./View";

export default function Historic({ assistantId }) {
    return (<div>
        <Routes>
            <Route path={'/'} element={<HistoricList assistantId={assistantId} />} />
            <Route path={'/:historic_id'} element={<View assistantId={assistantId} />} />
        </Routes>
    </div>)
}

function HistoricList({ assistantId }) {
    const [historic, setHistoric] = useState([]);
    const messageApi = useContext(MessageApiContext);
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(8);
    const [total, setTotal] = useState(0)


    const getSessions = useCallback(() => {
        setLoading(true);

        getGenerativeSessions({
            assistantID: assistantId,
            page,
            perPage,
        })
            .then(({ data, total }) => {
                setHistoric(data);
                setTotal(total);

                if (data.length === 0) return;

                return Promise.all(
                    data.map((item) =>
                        getGenerativeSessionHistory({ sessionID: item.sessionId })
                            .then(({ data: sessionData }) => {
                                const clientPhone = sessionData.find(
                                    (session) => session.clientData && session.clientData.phone
                                );

                                const name = clientPhone ? formatPhoneNumber(clientPhone.clientData.phone) : item.sessionId;

                                return {
                                    ...item,
                                    name: name,
                                };
                            })
                            .catch((err) => {
                                console.error(err);
                                return item;
                            })
                    )
                );
            })
            .then((updatedHistoric) => {
                if (updatedHistoric) setHistoric(updatedHistoric);
            })
            .catch((err) => {
                messageApi.error("Erro ao buscar histórico");
                console.error(err);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [assistantId, messageApi, page, perPage]);

    useEffect(() => {
        getSessions();
    }, [getSessions]);

    return (<List
        loading={loading}
        pagination={{
            position: "bottom", align: "end", onChange: (page, perPage) => {
                setPage(page);
                setPerPage(perPage);
            }, total, pageSize: perPage, current: page,
        }}
        locale={{
            emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={"Nenhum histórico encontrado"} />
        }}
        dataSource={historic}
        header={<Typography.Title level={3}>Histórico</Typography.Title>}
        renderItem={(item) => (<List.Item
            actions={[<Link to={`${item?.sessionId}`}>
                <Button type={'text'} icon={<EyeOutlined />} />
            </Link>]}
        >
            <List.Item.Meta
                style={{ cursor: 'pointer' }}
                title={<Link to={`${item?.sessionId}`}>
                    {item?.name}
                </Link>}
                description={updatedSince(new Date(item?.createdAt)) + ' atrás | ' + item?.conversations + ' Mensage' + (item?.conversations > 1 ? 'ns' : 'm')}
            />
        </List.Item>)}
    />)
}
